import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

// 추가 스토어
import modals from './modules/modals';
import page from './modules/page';
import setting from './modules/setting';
import event from './modules/event_modals';
import user from './modules/user';
import inplay from './modules/inplay';
import sports from './modules/sports';
import minigame from './modules/minigame';
import casino from './modules/casino';
import lotus from './modules/lotus';
import live from './modules/live';
import lsport from './modules/lsport';
import lsport_inplay from './modules/lsport_inplay';
import version from './modules/version';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    modals,
    page,
    setting,
    event,
    sports,
    inplay,
    minigame,
    casino,
    lotus,
    live,
    lsport,
    version,
    lsport_inplay,
  },
  plugins: [
    createPersistedState({
      paths: ["page", "user", "setting", "event"],
    }),
  ],
});


