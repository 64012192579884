<template>
  <div class="pop-body" style="padding: 10px;">
    <div class="inq-head">
      <div>
        <div class="tit1">조회일자</div>
        <button class="btn1" :class="{'active': payload.duration === 1}" @click="setDuration(1)">오늘</button>
        <button class="btn1" :class="{'active': payload.duration === 7}" @click="setDuration(7)">1주일</button>
        <button class="btn1" :class="{'active': payload.duration === 15}" @click="setDuration(15)">15일</button>
        <button class="btn1" :class="{'active': payload.duration === 30}" @click="setDuration(30)">1개월</button>
      </div>
      <div>
        <input type="text" class="inp" v-model="payload.startDate">
        <span>-</span>
        <input type="text" class="inp" v-model="payload.endDate">
      </div>
      <div>
        <a :class="{'active': !resultType}" @click="setResult(null)" class="btn2">전체</a>
        <a :class="{'active': resultType === 'wait'}" @click="setResult('wait')" class="btn2">대기</a>
        <a :class="{'active': resultType === 'hit'}" @click="setResult('hit')" class="btn2">당첨</a>
        <a :class="{'active': resultType === 'miss'}" @click="setResult('miss')" class="btn2">낙첨</a>
        <a :class="{'active': resultType === 'exception'}" @click="setResult('exception')" class="btn2">적특</a>
<!--        <a :class="{'active': resultType}" @click="setResult(null)" class="btn2">취소</a>-->
      </div>
    </div>
    <template v-for="(row, index) in lists">
      <div class="m-game3" :key="'bettingTitle'+index">
        <div class="title" style="margin: 5px 0px;">
          <div class="left">
            <div class="subj">베팅결과 : <strong class="yellow2">{{ row[0].betting_total_result | bettingStatus }}</strong></div>
          </div>
          <div class="right">{{ row[0].betting_regdatetime | formatDate('MM-DD HH:mm') }}</div>
        </div>
        <template v-for="(row2, index2) in row" >
          <div :key="'bets'+row2.bets_id+index2">
            <div class="title type2">
              <div class="left">
                <div class="subj">{{ row2.betting_game_leagues_name_kr }} &nbsp;&nbsp;[{{ row2.betting_score_home }}:{{ row2.betting_score_away }}]</div>
              </div>
              <div class="right">{{ row2.betting_game_starttime | formatDate('MM-DD HH:mm') }}</div>
            </div>
            <div class="game-item">
              <dl :class="{'active': row2.betting_side === 'home'}">
                <dt>{{ row2.betting_game_home_name_kr }}</dt>
                <dd><span>{{ row2.betting_odds1 }}</span></dd>
              </dl>
              <div class="vs" :class="{'active': row2.betting_side === 'draw'}" v-if="row2.betting_odds2">{{ row2.betting_odds2 }}</div>
              <div class="vs" v-else-if="row2.betting_line">{{ row2.betting_line }}</div>
              <div class="vs" v-else>VS</div>
              <dl :class="{'active': row2.betting_side === 'away'}">
                <dt>{{ row2.betting_game_away_name_kr }}</dt>
                <dd><span>{{ row2.betting_odds3 }}</span></dd>
              </dl>
            </div>
          </div>
        </template>
        <div class="bet-result">
          <dl>
            <dt>베팅금액</dt>
            <dd>{{ row[0].betting_bet_amount | makeComma }}원</dd>
          </dl>
          <dl>
            <dt>배당률</dt>
            <dd>{{ (Number(row[0].betting_total_odds) + Number(row[0].betting_total_odds_penalty)).toFixed(2) }}</dd>
          </dl>
          <dl>
            <dt>당첨예상금</dt>
            <dd>{{ row[0].betting_expected_prize | makeComma }}원</dd>
          </dl>
          <dl>
            <dt>당첨금</dt>
            <dd>{{ row[0].betting_payed_amount | makeComma }}원</dd>
          </dl>
        </div>
        <div class="btns">
          <a @click="addBettingList(row)" class="b2">첨부</a>
        </div>

      </div>
    </template>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "BettingAdd",
  props: [ 'openList', 'addBettingList' ],
  data: function(){
    return {
      path: 'betting/results',
      lists: null,
      originList: null,
      pagination: {},
      resultType: null,
      payload: {
        duration: 1,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      opened: [],
    }
  },
  mounted() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      return this.$store.dispatch('BETTING_HISTORY', { payload })
          .then(data => {
            const result = data.data.payload;
            this.originList = result.list;
            this.lists = this.originList;
            this.pagination = result.pagination;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    setDuration: function(num){
      this.payload.duration = num;
      this.payload.startDate = moment().subtract(num, 'days').format('YYYY-MM-DD')
      this.reqData(this.payload)
    },
    setResult: function(result){
      this.resultType = result;
      if(!result) {
        this.lists = this.originList;
        return;
      }
      this.list = this.originList.filter(e => e[0].betting_total_result === result);
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    toggleBetCode: function (code) {
      const index = this.opened.indexOf(code);
      if (index > -1) {
        this.selectedBet.splice(index, 1)
      } else {
        this.selectedBet.push(code)
      }
      console.log(this.selectedBet)
    },
  }
}
</script>

<style scoped>
@import 'https://pro.fontawesome.com/releases/v5.13.0/css/all.css';

.modal-wrapper {
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal {
  display: block;
  z-index: 10000;
  width: 1200px;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  margin: 50px auto;
  padding: 20px 30px;
  background-color: #1c2021;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
