<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 800px;">
    <div class="nbox" style="width: 1450px;">
      <div class="game-side" style="margin-top: 0px;">
        <div class="list">
          <a  @click="movePage('lotus/oe')" >
            <strong>홀짝</strong>
          </a>
          <a class="active">
            <strong>바카라1</strong>
          </a>
        </div>
      </div>
      <div class="contLeft">

        <!--        <ul class="tabs">-->
        <!--          <li><a @click="movePage('lotus/baccarat1')">로투스 바카라</a></li>-->
        <!--          <li><a @click="movePage('lotus/oe')">로투스 홀짝</a></li>-->
        <!--          <li><a @click="movePage('lotus/sicbo')">식보</a></li>-->
        <!--        </ul>-->

<!--        <div class="cateTabs">-->
<!--          <a @click="movePage('lotus/baccarat2')" class="on">로투스 바카라2</a>-->
<!--          <a @click="movePage('lotus/oe')" >홀짝</a>-->
<!--          <a @click="movePage('lotus/sicbo')" class="on">로투스 식보</a>-->
<!--        </div>-->
        <div class="gameBox">
          <iframe v-if="token" ref="gameIframe" :src="`https://stream03.lotusgaming01.com?gType=mid&token=${token}`" frameborder="0" scrolling="no" style="width:888px;height:660px;" allowtransparency="true"></iframe>
        </div>


        <h3 class="tit-bet">베팅내역</h3>
        <table class="listTable03">
          <tbody>
            <tr>
              <th>베팅일시</th>
              <th>회차</th>
              <th>베팅</th>
              <th>베팅액</th>
              <th>결과</th>
              <th>적중액</th>
            </tr>
            <tr v-for="(row, index) in lists" :key="'betBaccarat1Result' + index">
              <td>{{ row[0].betting_regdatetime | formatDate('YYYY-MM-DD') }}</td>
              <td>{{ row[0].betting_round }}</td>
              <td>
                <span :class="{
                'blue': row[0].betting_total_result === 'hit',
                'red': row[0].betting_total_result === 'miss',
                }">{{ row[0].betting_bet_name === 'Player' ? '플레이어' : row[0].betting_bet_name === 'Banker' ? '뱅커' : '타이' }}</span></td>
              <td>{{ row[0].betting_bet_amount | makeComma }}</td>
              <td><span
                  :class="{
                'blue': row[0].betting_total_result === 'hit',
                'red': row[0].betting_total_result === 'miss',
              }">
                {{ row[0].betting_total_result | bettingStatus }}
              </span></td>
              <td><strong class="black">{{ row[0].betting_payed_amount | makeComma }}</strong></td>
            </tr>

          </tbody>
        </table>

<!--        <div class="pagingBox black">-->

<!--          <div class="paging">-->
<!--            <a href="javascript:void(0)" class="btnFirst"><img src="@/assets/img/ico/ico_first_black.png"></a>-->
<!--            <a href="javascript:void(0)" class="btnPrev"><img src="@/assets/img/ico/ico_prev_black.png"></a>-->

<!--            <a href="javascript:void(0)" class="on">1</a>-->
<!--            <a href="javascript:void(0)">2</a>-->
<!--            <a href="javascript:void(0)">3</a>-->
<!--            <a href="javascript:void(0)">4</a>-->
<!--            <a href="javascript:void(0)">5</a>-->
<!--            <a href="javascript:void(0)">6</a>-->
<!--            <a href="javascript:void(0)">7</a>-->
<!--            <a href="javascript:void(0)">8</a>-->
<!--            <a href="javascript:void(0)">9</a>-->
<!--            <a href="javascript:void(0)">10</a>-->

<!--            <a href="javascript:void(0)" class="btnNext"><img src="@/assets/img/ico/ico_next_black.png"></a>-->
<!--            <a href="javascript:void(0)" class="btnLast"><img src="@/assets/img/ico/ico_last_black.png"></a>-->
<!--          </div>-->
<!--        </div>-->



      </div>

      <div class="contRight" style="position: absolute !important; left: calc(62vw + 200px);">
        <Baccarat1Cart :game="game" :get-game="getGame"></Baccarat1Cart>
      </div>
    </div>

  </section>
  <div class="mini-game" v-else>
    <div class="game-wrap" style="width:100%; height: 690px; margin: 0 auto;">
      <iframe v-if="token" ref="gameIframe" :src="`https://stream03.lotusgaming01.com?gType=mo&token=${token}`" frameborder="0" scrolling="no" style="width:100%;height:690px;" allowtransparency="true"></iframe>
    </div>
    <MBaccarat1Cart :game="game" :get-game="getGame"></MBaccarat1Cart>
    <table class="listTable02 listTable002" style="width: 100%">
      <caption>배팅내역</caption>
      <tbody>
      <tr>
        <td>베팅일시</td>
        <td>회차</td>
        <td>베팅</td>
        <td>베팅액</td>
        <td>적중액</td>
        <td>결과</td>
      </tr>
      <tr v-for="(row, index) in lists" :key="'minigameList'+index">
        <td style="background: #fff;  border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;">{{ row[0].betting_regdatetime | formatDate('MM-DD HH:mm:ss') }}</td>
        <td style="background: #fff; border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;">{{ row[0].betting_round }} 회차</td>
        <td style="background: #fff; border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;">{{ row[0].betting_bet_name }}</td>
        <td style="background: #fff; border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;">{{ row[0].betting_bet_amount | makeComma }}</td>
        <td style="background: #fff; border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;">{{ row[0].betting_payed_amount | makeComma }}</td>
        <td style="background: #fff; border-left: 1px solid #efefef; border-bottom: 1px solid #efefef; color: #000;" ><span :class="{
              'blue': row[0].betting_total_result === 'hit',
              'red': row[0].betting_total_result === 'miss',
            }">{{ row[0].betting_total_result | bettingStatus  }}</span></td>
      </tr>
      <!--      <tr>-->
      <!--        <td colspan="6">검색된 내역이 없습니다.</td>-->
      <!--      </tr>-->
      </tbody>
    </table>
  </div>
</template>

<script>
import Baccarat1Cart from "@/components/Betting/Baccarat1Cart";
import MBaccarat1Cart from "@/components/Betting/MBaccarat1Cart";
import {mapState} from "vuex";
export default {
  name: "Baccarat1",
  components: {
    Baccarat1Cart,
    MBaccarat1Cart
  },
  data: function(){
    return {
      path: 'lotus/baca',
      token: null,
      game: null,
      lists: [],

    }
  },
  created: function(){
    const domain = window.location.hostname
    console.log(domain)
    this.getToken(domain);
    this.getGame();
    this.reqData();
    setTimeout(this.continuousSession, 5000);
    this.interval = setInterval(this.continuousSession, 60000);
    this.interval2 = setInterval(() => {
      this.reqData();
      this.initUserInfo();
    }, 10000);
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  mounted() {
    const config = this.user.members_detail_setting;
    if (!config.베팅권한.로투스바카라.active) {
      this.$store.dispatch('SET_ERR_MSG', '로투스는 점검중입니다.');
      this.$store.dispatch('SHOW_MODAL', 'error2');
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    initUserInfo: function(){
      return this.$store.dispatch('ME')
    },
    getToken: function(domain){
      return this.$store.dispatch('GET_BACARAT1_TOKEN', {domain})
          .then(data => {
            const result = data.data;
            this.token = result.payload.access_token;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    getGame: function(){
      return this.$store.dispatch('GET_BACARAT1')
          .then(data => {
            const result = data.data;
            this.game = result.payload.game;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    reqData: function(){
      const payload = {
        page: 1,
        size: 5,
        type: '바카라1'
      };
      return this.$store.dispatch('GET_LOTUS_HISTORY', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage:function(path){
      this.$router.push(`/${path}`)
    },
    continuousSession: function(){
      const msg = { game: 'baca', domain: window.origin};
      this.$refs.gameIframe.contentWindow.postMessage(msg, 'https://stream03.lotusgaming01.com')
    },
  }
}
</script>

<style scoped>
.powerball {
  height: 131px;
  margin: 0px 0px !important;
  padding: 5px 5px !important;
}
.listTable002 caption {
  background: #2e3334;
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.listTable002 td {
  height: 30px;
  font-size: 11px;
  background: #d1d1d1;
  color: #000;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
</style>
