import axios from 'axios';
import store from "@/store";
const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    waitList: [],
    inplayLink: [],
    inplayStared: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  LSPORT_INPLAY_TOTAL_ODDS: function(state) {
    return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
  },
}

const actions = {
  GET_LSPORT_INPLAY_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/lsport_inplay/list?sitename=viking`, params).then(
      (response) => {
        if (response.data) {
          commit("SET_INPLAY_LIST", response.data?.payload.list);
        }
        return response;
      },
      (error) => {
        // if (error.response.status === 401) return commit('LOGOUT');
        return Promise.reject(error);
      }
    );
  },
  // GET_INPLAY_WAIT_LIST({commit}, params) {
  //   return axios.post(`${API_URL}/v2/inplay/wait/list`, params)
  //     .then(
  //       response => {
  //         if (response.data){
  //           commit('SET_INPLAY_WAIT_LIST', response.data?.payload.list)
  //         }
  //         return response
  //       },
  //       error => {
  //         // if (error.response.status === 401) return commit('LOGOUT');
  //         return Promise.reject(error);
  //       })
  // },
  GET_LSPORT_INPLAY_DETAIL(_, params) {
    return axios.post(`${API_URL}/v2/lsport_inplay/detail?sitename=viking`, params)
      .then(
        response => {
          return response
        },
        error => {
          // if (error.response.status === 401) return commit('LOGOUT');
          return Promise.reject(error);
        })
  },
  // 배당 업데이트
  UPDATE_LSPORT_INPLAY_LIST_ODDS({commit}, data) {
    commit('SET_LSPORT_INPLAY_LIST_ODDS_UPDATE', data)
  },
  // 스코어 업데이트
  UPDATE_LSPORT_INPLAY_LIST_SCORE({commit}, data) {
    commit('SET_LSPORT_INPLAY_LIST_SCORE_UPDATE', data)
  },
  ADD_CART_LSPORT_INPLAY_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      return commit('POP_ITEM_LSPORT_INPLAY_CART_BY_FOLDER', obj); // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.LSPORT_INPLAY_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.indexOf(item => item.betsSeq === obj.betsSeq);
    if (cartItem < 0) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      // 동일경기는 기본적인 동일경기 설정
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
      // 조합 가능 구분 여부
      const betOption = store.state.setting.settings.sportConfig.combinationMix2.filter(e=> e.status === '사용' && e.inplay === true);

      // const betOption =  state.bettingOptions.filter(e => e.list === true)
      // console.log(obj);
      const sportsBetOption = betOption.filter(e => Number(e.sport) === obj.sportsId)
      // console.log(sportsBetOption);
      if ( dupItem.length >= 2 ) {
        return alert('동일경기에서 3가지 이상 조합은 불가능합니다.')
      }

      if (dupItem.length > 0) {
        // 종목별 조합 검증항목 배열에 담음
        const cartItemInMarketId = dupItem.map(e => e.marketsId);
        cartItemInMarketId.push(obj.marketsId);
        const verifyResult = [];
        // console.log(sportsBetOption)
        const alloweds = sportsBetOption.map(e => e.allowed);
        for (const i in alloweds){
          let verify = alloweds[i].filter(e => e !== null);
          let inResult = [];
          for (const j in verify){
            let combination = verify[j].id;
            // console.log(cartItemInMarketId);
            // console.log(cartItemInMarketId.indexOf(String(combination)), combination);
            inResult.push(cartItemInMarketId.indexOf(String(combination)));
          }
          verifyResult.push(inResult);
        }

        // 배열에 담긴 조합 검증항목을 체크
        let res = false;
        for (const v in verifyResult) {
          const verify = verifyResult[v];
          // console.log(verify);
          if (verify.indexOf(-1) < 0) res = true;
        }
        if (!res){
          store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
          store.state.modals.deduction = true;
          return;
        }

        // 축구일때 승무패 배당차감
        // const soccerOption = store.state.setting.settings.sportConfig.common.soccerCombination;
        // if (obj.sportsId === 6046 && soccerOption.active) {
        //   const SMP = state.cart.find(e => e.marketNameKr.indexOf('승무패') >= 0 && e.gameId === obj.gameId);
        //   if ((SMP && SMP.betsName === 'X') || obj.betsName === 'X') {
        //     store.state.modals.errorMsg = '해당 조합은 베팅이 불가합니다.';
        //     store.state.modals.deduction = true;
        //     return
        //   }

        //   store.state.modals.errorMsg = '축구 동일경기 조합베팅시 차감 배당값으로 적용됩니다.';
        //   store.state.modals.deduction = true;

        //   // 이미 승무패가 등록되어있을시
        //   if (SMP){
        //     if (Number(SMP.odds) >= 1.01 && Number(SMP.odds) < 1.50) {
        //       SMP.betsPrevOdds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
        //       SMP.odds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
        //       SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
        //     } else if (Number(SMP.odds) >= 1.51 && Number(SMP.odds) < 1.99) {
        //       SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
        //       SMP.odds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
        //       SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
        //     } else {
        //       SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
        //       SMP.odds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
        //       SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
        //     }
        //   }
        //   // 승무패가 후에 추가될시
        //   if (obj.marketNameKr.indexOf('승무패') >= 0) {
        //     if (Number(obj.odds) >= 1.01 && Number(obj.odds) < 1.50) {
        //       obj.betsPrevOdds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
        //       obj.odds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
        //       obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
        //     } else if (Number(obj.odds) >= 1.51 && Number(obj.odds) < 1.99) {
        //       obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
        //       obj.odds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
        //       obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
        //     } else {
        //       obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
        //       obj.odds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
        //       obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
        //     }
        //   }
        // }
      }
      commit('PUSH_ITEM_LSPORT_INPLAY_CART', obj);
    } else {
      commit('POP_ITEM_LSRPOT_INPLAY_CART_INDEX', cartItem); // 있을시 삭제
    }
  },
  ALL_LSPORT_INPLAY_CLEAR_ITEM({ commit }){
    return commit('RESET_LSPORT_INPLAY_CART')
  },
  TOGGLE_STARED({commit}, data){
    commit('SET_IN_PLAY_LINKED_LIST', data)
  },
  DELETE_CART_INPLAY_ITEM({ commit }, obj){
    commit('POP_ITEM_INPLAY_CART', obj); // 있을시 삭제
  },
  DELETE_CART_INPLAY_BY_GAME_SEQ({ commit }, obj){
    commit('POP_ITEM_INPLAY_CART_BY_GAME_SEQ', obj); // 있을시 삭제
  },
  DELETE_CART_INPLAY_BY_GAME_ID({ commit }, id){
    commit('POP_ITEM_INPLAY_CART_BY_GAME_ID', id); // 있을시 삭제
  },
}

const mutations = {
  SET_INPLAY_LIST(state, payload) {
    state.originList = payload
  },
  SET_INPLAY_WAIT_LIST(state, payload) {
    state.waitList = payload
  },
  SET_DATE(state, payload) {
    state.dateCnt = payload
  },
  // 배당 소켓 업데이트
  SET_LSPORT_INPLAY_LIST_ODDS_UPDATE(state, data) {
    // 1. originList 업데이트
    // 2. 엘스포츠 카트 업데이트 및 정리
    for (const folder of data){
      const dbGame = state.originList.find((listGame) => String(listGame.id) === String(folder.gameId));
      // 일치하는 폴더 정보가있을시
      if (dbGame.folders.length === 0) continue;
      // 리스트 업데이트
      const inListfolder = dbGame.folders.find((gameFolder) => gameFolder.id === folder.id);
      if (inListfolder) {
        inListfolder.isSuspended = folder.isSuspended;
        for (const rmqBet of folder.bets) {
          const inBet = inListfolder.bets.find((inLiBet) => inLiBet.id === rmqBet.id);
          // console.log(inBet, '디비 BET');
          inBet.price = rmqBet.price;
          inBet.Status = rmqBet.status;
        }
      }

      // 카트정보 업데이트
      const inCartItemIndex = state.cart.findIndex((cartItem) => String(cartItem.foldersId) === String(folder.id));
      if (inCartItemIndex >= 0) {
        // 폴더 isSuspended y 일시 카트에서 제거
        if (folder.isSuspended === 'y') state.cart.splice(inCartItemIndex, 1)

        for (const rmqBet of folder.bets) {
          const inCartItem = state.cart.find((cartItem) => String(cartItem.betsId) === String(rmqBet.id));
          if (inCartItem) {
            inCartItem.price = rmqBet.price;
            inCartItem.status = rmqBet.status;
          }
        }
      }
    }

  },
  SET_LSPORT_INPLAY_LIST_SCORE_UPDATE(state, data) {
    // 1. originList 업데이트
    // 2. 엘스포츠 카트 업데이트 및 정리
    for (const game of data){
      const dbGame = state.originList.find((listGame) => String(listGame.id) === String(game.id));
      // 리스트 업데이트
      if (dbGame) {
          dbGame.homeResult = game.homeResult;
          dbGame.awayResult = game.awayResult;
          if (game.set1Score) dbGame.set1Score = game.set1Score;
          if (game.set2Score) dbGame.set2Score = game.set2Score;
          if (game.set3Score) dbGame.set3Score = game.set3Score;
          if (game.set4Score) dbGame.set4Score = game.set4Score;
          if (game.set5Score) dbGame.set5Score = game.set5Score;
          if (game.set6Score) dbGame.set6Score = game.set6Score;
          if (game.set7Score) dbGame.set7Score = game.set7Score;
          if (game.set8Score) dbGame.set8Score = game.set8Score;
          if (game.set9Score) dbGame.set9Score = game.set9Score;
          if (game.currentPeriod) dbGame.currentPeriod = game.currentPeriod;
          if (game.currentTime) dbGame.currentTime = game.currentTime;
      }

      // 카트정보 업데이트
      const inCartItem = state.cart.find((cartItem) => String(cartItem.gameId) === String(game.id));
      if (inCartItem) {
          inCartItem.homeScore = game.homeResult;
          inCartItem.awayScore = game.awayResult;
          if (game.currentPeriod) inCartItem.currentPeriod = game.currentPeriod;
          if (game.currentTime) inCartItem.currentTime = game.currentTime;
      }
    }
  },


  RESET_LSPORT_INPLAY_CART(state){
    state.cart = [];
  },
  SET_IN_PLAY_LINKED_LIST(state, data) {
    const index = state.inplayLink.findIndex(e => e.id === data.id)
    if (index >= 0){
      return state.inplayLink.splice(index, 1);
    }
    return state.inplayLink.push(data);
  },
  POP_ITEM_LSRPOT_INPLAY_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_LSRPOT_INPLAY_CART_INDEX(state, index){
    return state.cart.splice(index, 1);
  },
  PUSH_ITEM_LSPORT_INPLAY_CART(state, obj){
    state.cart.push(obj)
  },
  POP_ITEM_LSPORT_INPLAY_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.foldersSeq === obj.foldersSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_INPLAY_CART_BY_GAME_SEQ(state, obj){
    const index = state.cart.findIndex(i => i.gameSeq === obj.gameSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },

  POP_ITEM_INPLAY_CART_BY_GAME_ID(state, id){
    const index = state.cart.findIndex(i => String(i.gameId) === String(id));
    if (index === -1) return false;
    return state.cart.splice(index, 1);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
