const getDefaultState = () => {
  return {
    version: 'VK 0.2.5',
  }
}

const state = getDefaultState()

const getters = {}

const actions = {

}

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
}
