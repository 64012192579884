<template>
  <dl :class="{'active': checkCart(bets)}" @click="addCart(bets, folder)"
    v-if="folder.isSuspended === 'n'"
  >
    <dt>
    <!-- <dt v-if="type !== 'draw'"> -->
      {{ teamName }} {{ line }}
    </dt>
    <!-- <dt v-else>
      무
    </dt> -->
    <dd>
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="orange--text" style="margin-right: 5px;">{{ price }}</span>
      <span style="font-size: 10px; color: #fff;">
        {{ startPrice }}
      </span>
    </dd>
  </dl>
  <dl v-else>
    <dt>
      {{ teamName }} {{ line }}
    </dt>
    <dd>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  computed: {
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      let name = this.bets.name;
      if (this.bets.name in ['1', '2']) {
        if (this.bets.name === '1') name = `승 `;
        if (this.bets.name === '2') name = `패 `;
        if (this.bets.line && this.bets.line !== '') name += `(${this.bets.line.replace(' (0-0)', '')})`;
      }
      if (this.bets.name === 'Over') return `오버 ${this.bets.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.bets.line}`;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'No Goal') name = '노골';
      if (this.folder.market.isHandicap === 'y') name += ` (${this.bets.line})`;
      if (name?.indexOf('W1andOver') >= 0){
        name = name.replace('W1andOver', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('W2andOver') >= 0){
        name = name.replace('W2andOver', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('12andOver') >= 0){
        name = name.replace('12andOver', '12 & 오버')
      }
      if (name?.indexOf('W1andUnder') >= 0){
        name = name.replace('W1andUnder', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('W2andUnder') >= 0){
        name = name.replace('W2andUnder', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('12andUnder') >= 0){
        name = name.replace('12andUnder', '12 & 언더')
      }

      if (name?.indexOf('1XandOver') >= 0){
        name = name.replace('1XandOver', '1X & 오버')
        return name;
      }
      if (name?.indexOf('XandOver') >= 0){
        console.log(name);
        name = name.replace('XandOver', '무 & 오버')
      }
      if (name?.indexOf('X2andOver') >= 0){
        name = name.replace('X2andOver', 'X2 & 오버')
      }
      if (name?.indexOf('1XandUnder') >= 0){
        name = name.replace('1XandUnder', '1X & 언더')
      }
      if (name?.indexOf('XAndUnder') >= 0){
        name = name.replace('XAndUnder', '무 & 언더')
      }
      if (name?.indexOf('X2andUnder') >= 0){
        name = name.replace('X2andUnder', 'X2 & 언더')
      }
      if (name?.indexOf('ormore') >= 0){
        name = name.replace('ormore', '골 또는 그이상')
      }
      return name;
    },
    line: function(){
      if (this.folder.folders_line) {
        if (this.folder.folders_line.indexOf('(') >= 0) {
          return `(${this.folder.folders_line.split(' ')[0]})`
        }
        return `(${this.folder.folders_line})`
      }
      return ''
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
