<template>
  <div v-if="this.$store.state.page.isMobile">
    <transition name="fade">
      <div class="quick-cart" @click="toggleCart" v-if="cartItem.length > 0">
        <button class="btn-cart"><span>{{ cartItem.length }}</span></button>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal fade" :class="{'in': isView }" id="modalCart" v-if="isView" style="display: block;">
        <div class="modal-dialog" >
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal-cart">
                <div class="close">
                  <button @click="closeCart" class="btn-close" data-dismiss="modal"></button></div>
                <div class="box1">
                  <p>배팅 슬립</p>
                  <p>배당변경 자동적용</p>
                  <div>
                    <label><input type="checkbox" class="switch" :checked="isUpdate" @click="isUpdate = !isUpdate"><span><em></em></span></label>
                  </div>
                </div>
                <!-- <div class="tab-box">
                  <div class="btns">
                    <button :class="{'active': isSingle}" @click="changeSingle(true)">싱글</button>
                    <button :class="{'active': !isSingle}" @click="changeSingle(false)">다폴더</button>
                  </div>
                  <button @click="clearCart" class="btn-trash"></button>
                </div> -->
                <div class="body">
                  <div class="tab-cont" v-if="cartItem.length === 0">
                    베팅을 원하시면 <br>
                    원하시는 경기의 배당을 선택해 주세요.
                  </div>
                  <div class="tab-cont" v-else-if="cartItem.length > 0">

                    <!-- // 싱글 -->
                    <!-- <MSingle v-if="isSingle" :clear-cart="clearCart" :single-bet-cart-set-amount="singleBetCartSetAmount" :fixed="fixed" :is-update="isUpdate"></MSingle>
                    <MMulti v-else :clear-cart="clearCart" :fixed="fixed" :is-update="isUpdate"></MMulti> -->
                    <MMulti :clear-cart="clearCart" :fixed="fixed" :is-update="isUpdate"></MMulti>
                    <!-- // 다폴더 -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import MSingle from './MSpecialSingle'
import MMulti from './MSpecialMulti'
import {mapState} from "vuex";

export default {
  props: {
    type: String,
    fixed: {
      type: Boolean,
      default: false
    },
  },
  name: "MKoreanCart",
  components: {
    // SingleBet,
    // MSingle,
    MMulti,
  },
  data: function(){
    return {
      // isSingle: false,
      //베팅 진행중
      process: false,
      complete: false,
      sticky: false,
      upHere: false,
      isUpdate: true,
    }
  },
  computed:{
    ...mapState({
      isView: state => state.modals.cart,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  watch:{
    // isSingle: function(val){
    //   if (val){
    //     this.singleBetCartSetAmount()
    //   }
    // },
    // cartItem: {
    //   handler: function (val) {
    //     // 베팅타입 설정
    //     if (val.length === 1) {
    //       this.isSingle = true;
    //     }
    //     if (val.length > 1) {
    //       this.isSingle = false;
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    clearCart: function (){
      return this.$store.dispatch('ALL_CLEAR_ITEM')
    },
    closeCart: function (){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    // changeSingle: function(is){
    //   this.isSingle = is;
    // },
    singleBetCartSetAmount(){
      for (const c in this.cartItem){
        this.cartItem[c].betAmount = 0;
        this.cartItem[c].totalResult = this.cartItem[c].betAmount * this.cartItem[c].odds;
      }
    },
    toggleCart(){
      return this.$store.dispatch('SHOW_MODAL', 'cart')
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
