<template>
  <div class="cont" style="display: block;">
    <div class="cart-item" :class="{'bed': item.betsStatus !== 'opened'}" v-for="(item, idx) in cartItem" :key="'MultiMcartItem'+idx">
      <div class="cols">
        <div class="left">{{ item.homeNameKr || item.homeNameEn }} <span class="vs">vs</span><br>{{ item.awayNameKr ? item.awayNameKr : item.awayNameEn }}</div>
        <div class="right"><button @click="deleteItemCart(item)" class="btn-del"><span class="material-icons">close</span></button></div>
      </div>
      <div class="cols">
        <div class="left">{{ item.marketNameKr || item.marketNameEn }}</div>
        <div class="right" style="color: #506570;">({{ item.betsPrevOdds }})</div>
      </div>
      <div class="cols">
        <div class="left"><span class="main_color">{{ item | cartBetsName }}</span></div>
        <div class="right">{{ item.odds }}</div>
      </div>
    </div>
    <div class="bet-area">
      <dl>
        <dt>배당율</dt>
        <dd> {{ odds }}</dd>
      </dl>
      <dl>
        <dt>베팅금액</dt>
        <dd>
          <div class="price-btns">
            <input type="number" class="inp1" v-model="amount">
            <button class="btn1" @click="maxBet">맥스</button>
          </div>
        </dd>
      </dl>
      <dl>
        <dt>예상당첨금액</dt>
        <dd><span class="main_color">{{ winnings | makeComma }}</span>원</dd>
      </dl>
      <ul class="m_money_ul" style="flex-wrap: nowrap; margin-top: 10px;">
        <li>
          <button @click="addAmount(5000)">5천</button>
        </li>
        <li>
          <button @click="addAmount(10000)">1만</button>
        </li>
        <li>
          <button @click="addAmount(50000)">5만</button>
        </li>
        <li>
          <button @click="addAmount(100000)">10만</button>
        </li>
        <li>
          <button @click="addAmount(500000)">50만</button>
        </li>
        <li>
          <button @click="addAmount(1000000)">100만</button>
        </li>
      </ul>
      <ul class="m_money_ul">
        <li class="red">
          <button @click="maxBet">최대배팅</button>
        </li>
        <li class="red" style="margin-left: 6px !important;">
          <button @click="resetAmount">지우기</button>
        </li>
      </ul>
      <div class="bet-btn">
        <button @click="processBet">베팅하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MKoreanMulti",
  props: ['clearCart', 'isUpdate', 'fixed'],
  data: function(){
    return {
      path: 'betting/korean/process',
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
    }
  },
  computed:{
    odds: function(){
      return Number(this.$store.getters["SPORT_TOTAL_ODDS"])
    },
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.prematchSetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.prematchSetting.maxBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.prematchSetting.manyOther.maxWin);
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.prematchSetting.soloOther.maxBetting);
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.prematchSetting.soloOther.maxBetting);
    },
    maxOneFolderWinning: function(){
      return Number(this.levelSetting.prematchSetting.soloOther.maxWin);
    },
    maxOdds: function(){
      return this.levelSetting.prematchSetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return alert('최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
        }
        if (this.user.members_cash < Number(val)) {
          this.amount = this.user.members_cash;
          return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
        }
        this.calWinnings();
      },
      immediate: true,
    },
    cartItem: {
      handler: function(val){
        if (val.length === 1) this.limitWinnings = this.maxOneFolderWinning;
        if (val.length > 1) this.limitWinnings = this.maxWinAmount;
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        alert(`당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`)
      }
    }
  },
  methods: {
    deleteItemCart: function(obj){
      this.$store.dispatch('DELETE_CART_ITEM', obj)

    },
    maxBet: function(){
      const values = [Math.floor(this.limitWinnings/this.odds), this.maxAmount, this.user.members_cash]

      this.amount = Math.min.apply(null, values)
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // if (this.winnings < this.limitWinnings){
      //   this.amount = Math.floor(this.limitWinnings / this.odds);
      // }
    },
    processBet: function(){
      let data = {};
      if (this.fixed) {
        this.$store.dispatch('SET_ERR_MSG', '점검중 입니다.');
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      if (this.amount < this.minAmount) {
        this.$store.dispatch('SET_ERR_MSG', `최소 베팅금은 ${this.minAmount}원 입니다.`);
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      if (this.cartItem.find(b => Number(b.odds) <= 1 )){
        this.$store.dispatch('SET_ERR_MSG', '1배당이 포함되어 베팅이 불가합니다.');
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      if (this.odds > this.maxOdds) {
        this.$store.dispatch('SET_ERR_MSG', '최대 배당을 초과하여 베팅할 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return
      }

      // 유저설정 우선
      if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) {
            return;
          }
          this.singleBetDeduction()
        }
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.two) < 0
      ){
        let confirmMsg = `투폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.two}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.two, '유저설정-투폴베팅')
      }
      else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && this.twoFolderDeduction){
          let confirmMsg = `투폴 베팅시 ${this.twoFolderDeduction}% 배당 하락이 발생합니다.`;
          const doubleConfirm = confirm(confirmMsg);
          if (!doubleConfirm) {
            return;
          }
          this.doubleBetDeduction()
        }
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 3 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.three) < 0
      ){
        let confirmMsg = `세폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴베팅')
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length > 3 &&
          this.user.is_discount_three_over === 'Y'
      ){
        let confirmMsg = `세폴 베팅 초과시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴초과베팅')
      }
      
      data.bettings = this.cartItem;
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);

      if (this.cartItem.find(b => b.betsStatus === 'y')){
        this.$store.dispatch('SET_ERR_MSG', '베팅이 불가능한 폴더 있습니다.');
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }

      if (!this.isUpdate && this.cartItem.find(e => String(e.betsPrevOdds) !== String(e.odds) && e.betType !== '보너스')){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdate')
        return;
      }

      if (this.isUpdate && this.cartItem.find(e => String(e.betsPrevOdds) !== String(e.odds) && e.betType !== '보너스')){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdateProcess')
        return;
      }

      this.$store.dispatch('INVISIBLE_ALL_MODALS')
      this.$store.dispatch('SHOW_MODAL', 'bettingProcess')
      //다폴더

      this.amount = 0;
      this.clearCart();
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    doubleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.twoFolderDeduction;
        this.cartItem[c].penaltyReason = '투폴 베팅';
      }
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    addAmount(amt){
      this.amount += amt;
    },
    resetAmount(){
      this.amount = 0;
    }
  }
}
</script>

<style scoped>

</style>
