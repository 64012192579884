<template>
  <section v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">미니게임</h2>-->


    <div class="nbox">
      <SideRight></SideRight>
      <div class="contLeft">
        <div class="gameBox">
          <iframe name="miniViewFrame" id="miniViewFrame" src="https://named.com/games/powerball/view.php" frameborder="0" scrolling="no" style="width:888px;height:660px;" allowtransparency="true"></iframe>
        </div>

        <div class="btnBox">
          <a @click="initFolder" class="btn02">회차 새로고침</a>
        </div>
        <PowerLadderFolders v-if="game" :game="game"></PowerLadderFolders>

        <div class="btnBox">
          <a href="javascript:void(0)" class="btn01">베팅내역새로고침</a>
        </div>
        <table class="listTable02">
          <colgroup>
            <col width="116" />
            <col width="244" />
            <col width="87" />
            <col width="116" />
            <col width="117" />
            <col width="101" />
            <col width="" />
          </colgroup>
          <tr>
            <th>베팅 회차</th>
            <th>베팅 내역</th>
            <th>당첨 유무</th>
            <th>베팅 시간</th>
            <th>베팅 액</th>
            <th>당첨금</th>
            <th>취소/삭제</th>
          </tr>
          <tr v-for="(row, index) in lists" :key="'minigameList'+index">
            <td>{{ row.betting_game }} 회차</td>
            <td></td>
            <td>{{ row.betting_total_result | bettingStatus  }}</td>
            <td>{{ row.betting_regdatetime | formatDate('MM-DD HH:mm') }}</td>
            <td>{{ row.betting_amount | makeComma }}</td>
            <td>{{ row.betting_payed_amount | makeComma }}</td>
            <!--            <td><a href="javascript:void(0)" class="btn01">취소</a></td>-->
          </tr>
          <!--          <tr>-->
          <!--            <td>852,123 회차</td>-->
          <!--            <td>2폴더이상+보너스배당</td>-->
          <!--            <td>진행중</td>-->
          <!--            <td>09-18 15:32</td>-->
          <!--            <td>5,000</td>-->
          <!--            <td>0</td>-->
          <!--            <td><a href="javascript:void(0)" class="btn01">취소</a></td>-->
          <!--          </tr>-->

        </table>
      </div>

      <Cart :type="cartType"></Cart>
    </div>

  </section>
  <div class="mini-game" v-else>
    <div class="game-wrap iframeContainer">
      <iframe src="https://bepick.net/live/powerladder/scrap"></iframe>
    </div>
    <MCartPowerLadder :type="cartType" :add-cart="addCart"  :game="game" :check-cart="checkCart"></MCartPowerLadder>
  </div>
</template>

<script>
import Cart from '../../../components/Betting/Cart'
import PowerLadderFolders from '../../../components/Game/MiniGame/PowerLadderFolders'
import SideRight from '../../../components/Game/MiniGame/SideRight'
import MCartPowerLadder from '../../../components/Betting/MCartPowerLadder'

export default {
  name: "PowerBall",
  components: {
    Cart,
    PowerLadderFolders,
    SideRight,
    MCartPowerLadder
  },
  data: function(){
    return {
      loading: {
        isLoading: true,
        color: '#0d6c9a',
        size: '80px'
      },
      path: 'game/mini/powerladder',
      game: null,
      lists: [],
      cartType: 'minigame',
    }
  },
  created: function(){
    this.$store.dispatch('SET_ERR_MSG', '미니게임은 이용하실 수 없습니다.');
    this.$store.dispatch('SHOW_MODAL', 'error2');
    this.initFolder();
  },
  methods: {
    initFolder: function(){
      return this.$store.dispatch('auth/post', {path: this.path })
          .then(data => {
            const result = data.data;
            this.game = result.payload.game;
            this.lists = result.payload.list;
            // console.log(this.game)
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    toggleModal: function(){
      this.historyModal = !this.historyModal
    },
    // 베팅카트에 담기
    addCart: function (bet, folder) {
      this.$store.dispatch('cart/actionMinigameCart', {
        gameId: this.game.game_id,
        betsSeq: bet.bets_seq,
        betsName: bet.bets_name,
        sportsId: null,
        foldersSeq: folder.folders_seq,
        marketsId: null,
        odds: bet.bets_price,
        line: null,
        marketNameKr: folder.folders_name,
        marketNameEn: folder.folders_name,
        leagueNameKr: 'EOS파워볼',
        leagueNameEn: 'EOS파워볼',
        leagueNameIcon: null,
        sportNameKr: '파워사다리',
        sportNameEn: '파워사다리',
        sportNameIcon: null,
        homeNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '3' : '좌',
        homeNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '3' : '좌',
        awayNameKr: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '4' : '우',
        awayNameEn: folder.folders_name.indexOf('홀/짝') !== -1 ? '홀' : folder.folders_name.indexOf('줄수') !== -1 ? '4' : '우',
        startTime: this.game.game_starttime,
        odds1: '1.95',
        odds2: '',
        odds3: '1.95',
        betType: '미니게임',
      })
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      // console.log(this.$store.state.cart[this.cartType]);
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.$store.state.cart['minigame'].find(item => item.betsSeq === bets.bets_seq)
        return !!index;
      }
    },
  }
}
</script>

<style scoped>
/*.iframeContainer {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  overflow: hidden;*/
/*  height: 280px;*/
/*}*/
/*.iframeContainer iframe {*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/
</style>
