<template>
  <div class="m-footer">
    <div class="sns">
      <a href="https://t.me/+trtcDuu67CcxNDI0"><img src="@/assets/img/ico/telegram-icon.png"> <span style="margin-left: 5px;">공지방</span></a>
      <router-link to="/bbs/c/l"><img src="@/assets/img/ico/telegram-icon1.png"> <span style="margin-left: 5px;">고객센터</span></router-link>
    </div>
    <div class="t1"><img src="@/assets/img/etc/footer_partner.jpg"></div>
    <div class="logos">
      <table>
              <tr>
                <td style="text-align: center; width: 122px">
                  <a href="https://pu-11.com/" style="margin-left: auto; margin-right:auto;">
                    <img src="@/assets/img/main/vikbet_logo_main.png" alt="1" style="height: 30px;">
                  </a>
                </td>
                <td style="text-align: center; width: 122px">
                  <a href="https://luna-11.com/">
                    <img src="@/assets/img/main/luna_logo_main.png" alt="2" style="height: 30px;">
                  </a>
                </td>
                <td style="text-align: center; width: 122px">
                  <a href="https://ki-11.com/">
                    <img src="@/assets/img/main/kiki_logo_main.png" alt="3" style="height: 30px;">
                  </a>
                </td>
              </tr>
              <tr style="height: 20px;"></tr>
              <!-- <tr>
                <td>
                  <img src="@/assets/img/footer/4.jpg" alt="4">
                </td>
                <td>
                  <img src="@/assets/img/footer/5.jpg" alt="5">
                </td>
                <td>
                  <img src="@/assets/img/footer/6.jpg" alt="6">
                </td>
              </tr> -->
              <tr>
                <!-- <td></td> -->
                <td style="text-align: center;" colspan="3">
                  <a href="https://www.vkingbet.com/ko">
                    <img src="@/assets/img/main/바이킹 로고.png" alt="3" style="height: 30px;">
                  </a>
                </td>
                <!-- <td></td> -->
              </tr>
            </table>
    </div>

    <div class="foot-txt">
      Gambling can be addictive. Play responsibly.<br>
      vwww.gamblingtherapy.org &amp; www.gamblersanonymous.org<br><br>
      ALL RIGHTS RESERVED. | +18
    </div>


    <div class="logo"><img src="@/assets/img/main/kai_logo_main.png" style="height: 50px;"></div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
  }
}
</script>

<style scoped>

</style>
