import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './helper/filters'
import './helper/plugins'


import VueMobileDetection from 'vue-mobile-detection'
import VStickyElement from 'vue-sticky-element';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import VueCookies from "vue-cookies";
import Vuetify from 'vuetify'
// import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

import './assets/css/common.css'
import './assets/css/default.css'
import './assets/css/default2.css'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(VueMobileDetection);
Vue.use(VStickyElement);
Vue.use(VueCookies);
Vue.use(Vuetify);

const SOCKET = io(process.env.VUE_APP_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 2000,
});

Vue.use(VueSocketIOExt, SOCKET);
const LSPORT_SOCKET = io('https://lsports.j6rgtq98rh95vq5wbqsrdacx3.xyz', {
  reconnection: true,
  reconnectionDelay: 2000,
});


Vue.prototype.$socketLsport = LSPORT_SOCKET;
// Sentry.init({
//   Vue,
//   dsn: "https://d3e2b2f913d7959a5a788d3a386099ab@o4504202983374848.ingest.sentry.io/4505680340844544",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       maskAllInputs: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  }),
  created(){
    // created lifecycle hook 생성
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('ME', userData);
    }
  },
  render: h => h(App),
}).$mount('#app')




