<template>
  <div class="modal-wrapper">
    <div class="modal-mask" @click="openList">
    </div>
    <div class="modal" style="margin-top: 200px !important; height: 600px !important;">
      <div class="pop-body">
        <div class="inq-head">
          <div>
            <div class="tit1">조회일자</div>
            <button class="btn1" :class="{'active': payload.duration === 1}" @click="setDuration(1)">오늘</button>
            <button class="btn1" :class="{'active': payload.duration === 7}" @click="setDuration(7)">1주일</button>
            <button class="btn1" :class="{'active': payload.duration === 15}" @click="setDuration(15)">15일</button>
            <button class="btn1" :class="{'active': payload.duration === 30}" @click="setDuration(30)">1개월</button>
          </div>
          <div>
            <input type="date" class="inp" style="width: 120px;" v-model="payload.startDate">
            <span>-</span>
            <input type="date" class="inp" style="width: 120px;"  v-model="payload.endDate">
          </div>
          <div>
            <a class="btn2" :class="{'active': !resultType}" @click="setResult(null)">전체</a>
            <a class="btn2" :class="{'active': resultType === 'wait'}" @click="setResult('wait')">대기</a>
            <a class="btn2" :class="{'active': resultType === 'hit'}" @click="setResult('hit')">당첨</a>
            <a class="btn2" :class="{'active': resultType === 'miss'}" @click="setResult('miss')">낙첨</a>
            <a class="btn2" :class="{'active': resultType === 'exception'}" @click="setResult('exception')">적특</a>
<!--            <a href="#" class="btn2" :class="{'active': payload.status === '취소'}">취소</a>-->
          </div>
        </div>
        <div class="bet_wrap">
          <div class="example1">
            <div class="panel panel-primary">
              <div v-if="!lists" style="text-align: center; padding-top: 20%; padding-bottom: 20%;">
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
              <form name="cbFormAll1" class="ng-pristine ng-valid" v-else>
                <div id="tableAll1_wrapper" class="dataTables_wrapper no-footer">
                  <table id="tableAll1" cellspacing="0" cellpadding="0" width="100%" class="dataTable no-footer" role="grid" style="width: 100%;">
                    <thead>
                    <tr role="row">
                      <th class="sorting" style="width: 220px;">베팅 NO</th>
                      <th class="sorting_desc" style="width: 120px;" aria-sort="descending">베팅 일시</th>
                      <th class="sorting_asc" style="width: 130px;">베팅 종목</th>
                      <th class="sorting" style="width: 130px;">베팅 결과</th>
                      <th class="sorting" style="width: 168px;">배팅 금액</th>
                      <th class="sorting" style="width: 143px;">배당율</th>
                      <th class="sorting" style="width: 144px;">예상 적중 금액</th>
                      <th class="sorting" style="width: 71px;">폴더</th>
                      <th class="sorting" style="width: 60px;">선택</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(row, index) in lists">
                      <tr id="tableall1_2802777" role="row" class="odd" :key="'bettingTitle'+index">
                        <td @click="toggle(index)">{{ row[0].betting_betcode }}</td>
                        <td @click="toggle(index)" class="sorting_1">{{ row[0].betting_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                        <td @click="toggle(index)">{{ row[0].betting_game_sports_name_kr }} {{ row.length > 1 ? '외' : '' }}</td>
                        <td @click="toggle(index)">
                          <span class="font_002"
                                :class="{
                                  'text-yellow': row[0].betting_total_result === 'exception',
                                  'text-red': row[0].betting_total_result === 'miss',
                                  'text-green': row[0].betting_total_result === 'hit',
                                  'text-gray': row[0].betting_total_result === 'wait',
                              }"
                          >{{ row[0].betting_total_result | bettingStatus }}</span>
                        </td>
                        <td @click="toggle(index)"
                            :class="{'orange--text': row[0].betting_total_result === 'hit' || row[0].betting_total_result === 'exception'}"
                        >
                          <span class="">{{ row[0].betting_bet_amount | makeComma }}</span>
                        </td>
                        <td @click="toggle(index)"
                            :class="{'orange--text': row[0].betting_total_result === 'hit' || row[0].betting_total_result === 'exception'}"
                        >
                          <span class="">{{ (Number(row[0].betting_total_odds) + Number(row[0].betting_total_odds_penalty)).toFixed(2) }}</span>
                        </td>
                        <td @click="toggle(index)"
                            :class="{'orange--text': row[0].betting_total_result === 'hit' || row[0].betting_total_result === 'exception'}"
                        >
                          <span class="">{{ row[0].betting_expected_prize | makeComma }}</span>
                        </td>
                        <td @click="toggle(index)">{{ row.length > 1 ? `${row.length}폴더` : '단폴더' }}</td>
                        <td><button @click="addBettingList(row)" style="border-radius: 5px; width: 35px;">선택</button></td>
                      </tr>
                      <transition name="fade" :key="'bettingDetail'+index">
                        <tr v-if="opened.includes(index)">
                          <td colspan="9">
                            <div class="sub_table">
                              <table id="sub_tableall1_2802725" class="panel-body bet_dd_p" cellpadding="0" cellspacing="0"
                                     :class="{
                                              'acc_list_table_in_01': row[0].betting_total_result === 'exception',
                                              'acc_list_table_in_02': row[0].betting_total_result === 'miss',
                                              'acc_list_table_in_04': row[0].betting_total_result === 'hit',
                                              'acc_list_table_in_03': row[0].betting_total_result === 'wait',
                                          }"
                              >
                                <tbody>
                                <tr>
                                  <td class="acc_list_table_in_t" width="11%">경기일시</td>
                                  <td class="acc_list_table_in_t" width="8%">종목</td>
                                  <td class="acc_list_table_in_t" width="15%">리그</td>
                                  <td class="acc_list_table_in_t" width="18%">팀</td>
                                  <td class="acc_list_table_in_t" width="10%">타입</td>
                                  <td class="acc_list_table_in_t" width="14%">배팅</td>
                                  <td class="acc_list_table_in_t" width="6%">배당율</td>
                                  <td class="acc_list_table_in_t" width="6%">결과</td>
                                </tr>
                                <tr v-for="(row2, index2) in row" :key="'bets'+row.bets_id+index2">
                                  <td class="bet_time">{{ row2.betting_game_starttime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                                  <td class="bet_event">{{ row2.betting_game_sports_name_kr }}</td>
                                  <td class="bet_name">{{ row2.betting_game_leagues_name_kr }}</td>
                                  <td class="bet_name">{{ row2.betting_game_home_name_kr }} - {{ row2.betting_game_away_name_kr }}</td>
                                  <td class="bet_type">{{ row2.betting_game_markets_name_kr }}</td>
                                  <td class="bet_type">{{ row2.betting_side === 'home' ? row2.betting_game_home_name_kr : row2.betting_game_away_name_kr }} <span v-if="row2.betting_line">({{ row2.betting_line }})</span></td>
                                  <td class="bet_type">{{ row.length === 1 ? (Number(row[0].betting_total_odds) + Number(row[0].betting_total_odds_penalty)).toFixed(2) : row2.betting_odds}}</td>
                                  <td class="beting_in_btn">
                                    <span class="font_002"
                                          :class="{
                                              'text-yellow': row2.betting_result === 'exception',
                                              'text-red': row2.betting_result === 'miss',
                                              'text-green': row2.betting_result === 'hit',
                                              'text-gray': row2.betting_result === 'wait',
                                          }"
                                    > {{ row2.betting_result | bettingStatus }}</span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>

                          </td>
                        </tr>
                      </transition>
                    </template>

                    </tbody>
                  </table>

                  <Pagination v-if="pagination.end" :pagination="pagination" :req-data="reqData" :payload="payload"></Pagination>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BettingAdd",
  props: [ 'openList', 'addBettingList' ],
  data: function(){
    return {
      path: 'betting/results',
      lists: null,
      originList: null,
      pagination: {},
      resultType: null,
      payload: {
        duration: 1,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      opened: [],
    }
  },
  mounted() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      return this.$store.dispatch('BETTING_HISTORY', { payload })
          .then(data => {
            const result = data.data.payload;
            this.originList = result.list;
            this.lists = this.originList;
            this.pagination = result.pagination;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    setDuration: function(num){
      this.payload.duration = num;
      this.payload.startDate = moment().subtract(num, 'days').format('YYYY-MM-DD')
      this.reqData(this.payload)
    },
    setResult: function(result){
      this.resultType = result;
      console.log(result)
      if(!result) {
        this.lists = this.originList;
        return;
      }
      this.list = this.originList.filter(e => e[0].betting_total_result === result);
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    toggleBetCode: function (code) {
      const index = this.opened.indexOf(code);
      if (index > -1) {
        this.selectedBet.splice(index, 1)
      } else {
        this.selectedBet.push(code)
      }
      console.log(this.selectedBet)
    },
  }
}
</script>

<style scoped>
@import 'https://pro.fontawesome.com/releases/v5.13.0/css/all.css';

.modal-wrapper {
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal {
  display: block;
  z-index: 10000;
  width: 1200px;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  margin: 50px auto;
  padding: 20px 30px;
  background-color: #1c2021;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
