<template>
  <section v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">스포츠</h2>-->

    <div class="inner" style="margin-top: 20px; padding: 0;">
      <div class="new_page_tabpc">
        <router-link to="/sport/korean"><span>엘스포츠 한국형</span></router-link>
        <router-link to="/sport/korean_new" class="cho"><span>벳컨 한국형</span></router-link>
      </div>
      <div class="cate2">
<!--        6046,687890,35232,48242,154830,154914,131506,1149121-->
        <div class="box">
          <a class="all" @click="changeCategory(null)">ALL</a>
          <a class="c2"  @click="changeCategory(210)">FOOTBALL</a>
          <a class="c1"  @click="changeCategory(220)">BASEBALL</a>
          <a class="c3"  @click="changeCategory(212)">BASKETBALL</a>
          <a class="c4"  @click="changeCategory(214)">VOLLEYBALL</a>
          <a class="c5"  @click="changeCategory(211)">HOCKEY</a>
          <a class="c6"  @click="changeCategory(267)">E-SPORTS</a>
          <a class="c7"  @click="changeCategory(215)">AMERICAN FOOTBALL</a>
          <a class="c8"  @click="changeCategory('ETC')">ETC</a>
        </div>
      </div>
    </div>

    <div class="nbox">
      <div class="contLeft" style="min-height: 1000px; width: 77%;">
        <table class="listTable01" v-if="bonus.length > 0" style="display: block;">
          <colgroup>
            <col width="129" />
            <col width="288" />
            <col width="138" />
            <col width="288" />
            <col width="74" />
          </colgroup>
          <tr>
            <th colspan="6">
              <img src="@/assets/img/ico/ico_hart.png" />
              &nbsp;&nbsp;<span>보너스 배당</span>
            </th>
          </tr>
          <tr v-for="(rowB, indexBonus) in bonus" :key="'bonusIndex'+indexBonus">
            <td>보너스배당</td>
            <td class="al" @click="addBonus(rowB)" :class="{'active': checkCart(rowB, '보너스')}">
              <div class="fbox">
                <span class="fl">{{ rowB.name }}이상+보너스배당</span>
                <span class="fr up">{{ rowB.rate }}</span>
              </div>
            </td>
            <td>VS</td>
            <td class="ar">
              <div class="fbox">
                <span class="fl">&nbsp;</span>
                <span class="fr">[{{ rowB.name }}↑ + 보너스] 위반시 낙첨처리</span>
              </div>
            </td>
            <td>진행</td>
          </tr>
        </table>
        <KoreanFolders v-if="isLoading" :lists="lists" :add-cart="addCart" :check-cart="checkCart"></KoreanFolders>
        <Loading style="text-align: center; margin-top: 20%;" v-else></Loading>
      </div>

      <!--      카트      -->
      <div class="contRight" :class="{'fixedCart': isScrollDown}" style="position:fixed; left: calc(calc(1190px * 0.77) + calc((100% - 1190px) / 2) + 10px);" v-if="!sticky">
        <NewCart :toggle-stick="toggleStick"></NewCart>
      </div>
      <div class="contRight" style="position:absolute; left: calc(55vw + 232px);" v-else>
        <NewCart :toggle-stick="toggleStick"></NewCart>
      </div>

    </div>
  </section>
  <div v-else>
    <div class="new_page_tab">
      <router-link to="/sport/korean" ><span>엘스포츠 한국형</span></router-link>
      <router-link to="/sport/korean_new" class="cho"><span>벳컨 한국형</span></router-link>
    </div>
    <div class="categorys" style="margin-top: 3px; padding: 0;">
      <button @click="changeCategory(null)" :class="{'active': !this.category}">전체</button>
      <button @click="changeCategory(210)" :class="{'active': this.category === 210}"><img src="@/assets/img/ico/1.png"></button>
      <button @click="changeCategory(220)" :class="{'active': this.category === 220}"><img src="@/assets/img/ico/11.png"></button>
      <button @click="changeCategory(212)" :class="{'active': this.category === 212}"><img src="@/assets/img/ico/3.png"></button>
      <button @click="changeCategory(214)" :class="{'active': this.category === 214}"><img src="@/assets/img/ico/5.png"></button>
      <button @click="changeCategory(211)" :class="{'active': this.category === 211}"><img src="@/assets/img/ico/2.png"></button>
      <button @click="changeCategory(267)" :class="{'active': this.category === 267}"><img src="@/assets/img/ico/77.png"></button>
      <button @click="changeCategory(215)" :class="{'active': this.category === 215}"><img src="@/assets/img/ico/6.png"></button>
      <button @click="changeCategory(238)" :class="{'active': this.category === 238}"><img  style="width: 20px;" src="@/assets/img/ico/29.png"></button>
    </div>


    <!-- 인디케이터 -->
    <div class="m-game3" v-if="!isLoading" style="min-height: 300px; text-align: center; padding-top: 50px;">
      <Loading></Loading>
    </div>

    <!-- 게임 데이터 -->
    <div class="m-game3" v-if="isLoading && lists.length === 0" style="min-height: 100px; text-align: center;">
      <div class="day" style="margin-top: 40px;">
        <span class="t2" style="text-align: center;">진행중인 경기가 없습니다.</span>
      </div>
    </div>
    <!-- 경기목록   -->
    <MWrapperFolder
        v-else-if="isLoading && lists.length > 0"
        :check-cart="checkCart"
        :add-cart="addCart"
        :lists="lists"
        :bonus="bonus"
        :add-bonus="addBonus"
    ></MWrapperFolder>
    <MCart></MCart>
  </div>
</template>

<script>
import MCart from '@/components/Betting/MKoreanCart'
import NewCart from '@/components/Betting/KoreanCart'
import Loading from '@/components/Loading'
import KoreanFolders from '@/components/Game/Sports/Korean/Folders2'
import MWrapperFolder from '@/components/Game/Sports/Korean/MWrapperFolder'
import MSG from "@/contants/msg";
import {mapState} from "vuex";

export default {
  name: "Sports",
  components: {
    MCart,
    NewCart,
    // Cart,
    KoreanFolders,
    // MKoreanPrice
    // MPrice,
    MWrapperFolder,
    Loading
  },
  data: function(){
    return {
      type: 'korean',
      category: null,
      isLoading: false,
      search: false,
      sticky: false,
      keyword: null,
      lists : null,
      isScrollDown: false,
    }
  },
  computed: {
    //보너스
    bonus: function(){
      return this.$store.state.setting.settings ? this.$store.state.setting.settings.config.option.bettingOption.sportsFolder.filter(e => e.active === true) : []
    },
    // 마감시간
    endTime: function(){
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '스포츠').endTime
    },
    // menuVisible(){
    //   return this.$store.getters['MENU_VISIBLE'];
    // },
    // europeanVisible(){
    //   return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    // },
    ...mapState({
      originList: state => state.sports.originList,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  watch: {
    category: function(val){
      if (!val) return this.lists = this.originList;
      if (val === 'ETC') return this.lists = this.originList.filter(game => [210, 212, 214, 220, 211, 267, 215].indexOf(Number(game.sportSeq)) < 0)
      this.lists = this.originList.filter(e=> e.sportSeq === val);
    },
  },
  mounted: function() {
    // if (!this.europeanVisible){
    //   this.$store.dispatch('SHOW_ERR_MSG3', '유럽형은 점검중 입니다.')
    // }
    window.addEventListener('scroll', this.handleScroll)
    this.$store.dispatch('CLEAR_SPORT_CART', '프라매치')
    this.initFolder()
    this.$socket.client.on('prematch', this.updateData)
    setInterval(this.removeGames, 1000)
  },
  beforeDestroy: function() {
    window.removeEventListener('scroll', this.handleScroll)
    this.$socket.client.off('prematch')
  },
  methods: {
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > 200) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    // 실시간 업데이트 데이터 처리
    updateData: function(data){
      if (this.lists && this.lists.length === 0) return;
      const result = JSON.parse(data)
      this.$store.dispatch('UPDATE_KOREAN', result);
    },
    // 폴더 불러오기
    initFolder: function(){
      return this.$store.dispatch('GET_KOREAN_LIST', { payload: { sitename: 'viking' }})
          .then(() => {
            this.isLoading = true;
            this.lists = this.originList;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    // 페이지 이동
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    // 게임종목
    changeCategory: function(sport){
      this.category = sport;
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.한국형.active) {
          return alert(MSG.authorization);
        }
      } else {
        return alert(MSG.isNotLogin);
      }
      const item = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportSeq,
        marketsId: folders.marketId,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line || null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr ,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        startTime: game.startTime,
        odds1: folders.bets.find(e => e.name === 'Home' || e.name === 'W1' || e.name === 'Over').price,
        odds2: folders.bets.find(e => e.name === 'X') ? folders.bets.find(e => e.name === 'X').price : null,
        odds3: folders.bets.find(e => e.name === 'Away' || e.name === 'W2' || e.name === 'Under').price,
        betType: '프리매치',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        penaltyReason: null,
      };
      this.$store.dispatch('ADD_CART_ITEM', item);
    },
    addBonus: function (bonus) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.한국형.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
      } else {
        return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);
      }
      this.$store.dispatch('ADD_CART_ITEM', {
        gameId: null,
        betsSeq: null,
        betsName: '1',
        sportsId: null,
        marketsId: null,
        foldersSeq: null,
        odds: bonus.rate,
        betsUpdated: false,
        betsPrevOdds: bonus.rate,
        line: null,
        leagueNameKr: '보너스',
        leagueNameEn: '보너스',
        leagueNameIcon: null,
        sportNameKr: '보너스',
        sportNameEn: '보너스',
        sportNameIcon: null,
        homeNameKr: bonus.name,
        homeNameEn: bonus.name,
        awayNameKr: '보너스' ,
        awayNameEn: '보너스',
        marketNameKr: '보너스',
        marketNameEn: '보너스',
        startTime: null,
        odds1: bonus.rate,
        odds2: bonus.rate,
        odds3: bonus.rate,
        betType: '보너스',
        detailType: null,
        currentPeriod: 0,
        currentTime: 0,
        homeScore: 0,
        awayScore: 0,
        penalty: null,
        penaltyReason: null,
      })
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type = '일반') {
      if (type === '보너스') {
        let index = this.cartItem.find(item => item.odds === bets.rate)
        return !!index;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    // 시간이 지난 항목들 안보이게 설정
    removeGames: function () {
      if (this.lists && this.lists.length > 0) {
        let endEvents = this.lists.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.lists.indexOf(ele)
          if (index>=0) {
            this.lists.splice(index, 1)
          }
        })
      }
      // 카트 아이템수정
      if (this.cartItem){
        let endEvents = this.$store.state.sports.cart.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.$store.state.sports.cart.indexOf(ele)
          if (index>=0) {
            this.$store.state.sports.cart.splice(index, 1)
          }
        })
      }
    },
    toggleStick: function(){
      this.sticky = !this.sticky;
    }
  },
}
</script>

<style scoped>
.event-detail-list-move {
  transition: transform 0.8s;
}
.event-detail-list {
  transition: transform 0.8s;
}
.categorys::-webkit-scrollbar{
  display:none;
}
.fixedCart {
  top: 80px;
}
</style>
