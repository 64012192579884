<template>
  <table width="100%" valign="top" border="0" cellspacing="0" cellpadding="0"  class="ng-isolate-scope">
    <tbody>
    <tr>
      <td width="100%">
        <!-- ngInclude: centerTab -->
        <div style="">
          <div style="display: block;" id="clive" class="animate-bottom ">
            <div class="game_center_wrap">
              <div  class="sports_wrap" >
                <template v-if="originList.length === 0">
                  <table class="sports_title_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td colspan="5">
                        <span class="live_tab_text_2"></span>
                      </td>
                      <!--                      <td width="7%" style="color: #d82731;">맥스</td>-->
                      <td width="110px">승</td>
                      <td width="110px">무</td>
                      <td width="110px">패</td>
                      <td width="4%">맥스</td>
                      <td width="6%" class="font_002">더보기</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="sports_table_1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td width="100%" align="center" style="padding:15px 0 15px 0; color:#8a8c8d; text-align: center;">
                        현재 진행중인 경기가 없습니다.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <template v-for="sport in sortBySports(lists)">
                    <table :key="'titleSportName'+sport.nameEn" class="sports_title_table_1" cellpadding="0" cellspacing="0">
                      <tbody>
                      <tr>
                        <td colspan="5">
                          <span class="live_tab_text_2"><img :src="sport.icon" style="width: 15px; height: 15px;" alt="">&nbsp;&nbsp;<span>{{ sport | sportName }}</span></span>
                        </td>
                        <!--                        <td width="7%" style="color: #d82731;">맥스</td>-->
                        <td width="110px">승</td>
                        <td width="110px">무</td>
                        <td width="110px">패</td>
                        <td width="4%">맥스</td>
                        <td width="6%" class="font_002">더보기</td>
                      </tr>
                      </tbody>
                    </table>
                      <table v-for="(game, gameIndex) in lists.filter(e => e.sportId === sport.id)" :key="'gameIndex'+gameIndex + sport.nameEn" class="sports_table_1"
                             cellpadding="0" cellspacing="0">
                        <template v-if="game.isVisible === 'y'">
                          <tbody v-if="game.status ==='종료' || game.isSuspended === 'y'">
                            <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2" v-if="game.status === '종료'">종료</td>
                            <td class="sports_3_2" v-else>{{ game | currentPeriodLsportInplay('period') }}</td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game.id)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game.id)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2" @click="selectedGame(game.id)" style="cursor: pointer;">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <!--                        <td class="sports_6_5">-->
                            <!--                          {{ game.league.maxAmount | amtKor }}-->
                            <!--                        </td>-->
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4" @click="selectedGame(game.id)">
                              +{{ game.foldersCnt.cnt }}
                            </td>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status ==='진행' && game.isSuspended === 'n'">
                          <tr style="background-color: rgb(34, 38, 39);">
                            <td class="sports_1" style="background-color: rgb(34, 38, 39);">
                              <a @click="addStaredGame(game)" style="color: #8a8c8d;">
                                <font-awesome-icon v-if="linkedGame.find(e => e.id === game.id)" icon="fa fa-star"/>
                                <font-awesome-icon v-else icon="fa-regular fa-star"/>
                              </a>
                            </td>
                            <td class="sports_3_2">
                              {{ game | currentPeriodLsportInplay('period') }}
                            </td>
                            <td class="sports_2_2" style="text-align: center; cursor: pointer;" @click="selectedGame(game.id)">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2" @click="selectedGame(game.id)" style="cursor: pointer;">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>
                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr @click="selectedGame(game.id)" style="cursor: pointer;">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <template v-if="checkBlockGame(game)">
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>
                            <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) > 0">
                              <td class="sports_7_3" @click="selectedGame(game.id)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_8_3" @click="selectedGame(game.id)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_9_3" @click="selectedGame(game.id)" style="color: orange;">
                                <font-awesome-icon icon="fs fa-circle-plus" style="color: orange;"/>
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4" @click="selectedGame(game.id)">
                                +{{ game.foldersCnt.cnt }}
                              </td>
                            </template>
                            <template v-else-if="game.folders.length === 0 && Number(game.foldersCnt.cnt) === 0">
                              <td class="sports_7_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_8_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_9_3">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                              <td class="sports_6_5">
                                {{ game.league.maxAmount | amtKor }}
                              </td>
                              <td class="sports_10_4">
                                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                              </td>
                            </template>
                            <template v-else>
                              <template
                                  v-if="game.folders[0].isSuspended === 'y' ||  game.folders[0].bets.find(b => Number(b.price) === 1)">
                                <template v-if="game.foldersCnt.cnt > 0">
                                  <td class="sports_7_3" @click="selectedGame(game.id)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_8_3" @click="selectedGame(game.id)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_9_3" @click="selectedGame(game.id)" style="color: orange;">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game.id)">
                                    <font-awesome-icon icon="fs fa-circle-plus"/>
                                  </td>
                                </template>
                                <template v-else>
                                  <td class="sports_7_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_8_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_9_3">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                  <td class="sports_6_5">
                                    {{ game.league.maxAmount | amtKor }}
                                  </td>
                                  <td class="sports_10_4" @click="selectedGame(game.id)">
                                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                                  </td>
                                </template>

                              </template>
                              <template v-else-if="game.folders[0].isSuspended === 'n'">
                                <td class="sports_7_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === '1'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === '1'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === '1').price"></ListPrice>
                                </td>
                                <td class="sports_8_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === 'X'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === 'X'))}"
                                >
                                  <ListPrice v-if="game.folders[0].bets.find(e => e.name === 'X')"
                                             :price="game.folders[0].bets.find(e => e.name === 'X').price"></ListPrice>
                                </td>
                                <td class="sports_9_3"
                                    @click="addCart(game.folders[0].bets.find(e => e.name === '2'), game.folders[0], game)"
                                    :class="{'sel': checkCart(game.folders[0].bets.find(e => e.name === '2'))}"
                                >
                                  <ListPrice
                                      :price="game.folders[0].bets.find(e => e.name === '2').price"></ListPrice>
                                </td>
                                <td class="sports_6_5">
                                  {{ game.league.maxAmount | amtKor }}
                                </td>
                                <td class="sports_10_4" @click="selectedGame(game.id)">
                                  +{{ game.foldersCnt.cnt }}
                                </td>
                              </template>
                            </template>
                          </tr>
                          </tbody>
                          <tbody v-else-if="game.status === '대기'">
                          <tr style="background-color: rgb(34, 38, 39);">
                            <td class="pre_0_0">
                              <a href="#">
                                <i class="fa fa-star-o"></i>
                              </a>
                            </td>
                            <td class="sports_3_2">대기중</td>
                            <td class="sports_2_2" style="text-align: center;">
                              {{ game.homeResult }} - {{ game.awayResult }}
                            </td>
                            <td class="sports_5_2">
                              <img style="width: 14px; height: 10px;" v-if="game.location.icon" :src="game.location.icon"
                                   alt="">
                              {{ game.league | leagueName }}
                            </td>

                            <td class="sports_6_2">
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr href="#">
                                  <td class="pre_4_1_1">{{ game.homeTeam | teamName }}</td>
                                  <td class="pre_4_1_2">vs</td>
                                  <td class="pre_4_1_3">{{ game.awayTeam | teamName }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td class="sports_7_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_8_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_9_3">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                            <td class="sports_6_5">
                              {{ game.league.maxAmount | amtKor }}
                            </td>
                            <td class="sports_10_4">
                              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                            </td>
                          </tr>
                          </tbody>
                          <RenewalInplayDetail
                            v-if="checkArray(game.id)"
                            :game="game"
                            :check-block-game="checkBlockGame"
                            :add-cart="addCart"
                            :check-cart="checkCart"
                            :block-type-setting="blockTypeSetting"
                            :socket="lsport_socket"
                          ></RenewalInplayDetail>
                        </template>
                      </table>

                  </template>

                </template>
              </div>
              <!--<div class="live_wrap">-->
              <!--  <table class="live_table">-->
              <!--    <tbody>-->
              <!--    <tr>-->
              <!--      <td class="live_table_t_1 "><span class="white">경기 일정</span></td>-->
              <!--    </tr>-->
              <!--    </tbody>-->
              <!--  </table>-->
                <!--                        경기일정 -->
              <!--  <table class="live_table bg-gray" v-for="(wait, index) in waitList" :key="'waitList' +index">-->
              <!--    <colgroup>-->
              <!--      <col style="width:18%">-->
              <!--      <col style="width:82%">-->
              <!--    </colgroup>-->
              <!--    <tbody>-->
              <!--    <tr>-->
              <!--      <td class="n-tit orange" style="width: 30%;">-->
              <!--        {{ wait.startTime | formatDate('M월 D일') }} ({{ wait.startTime | formatDate('요일') }})-->
              <!--        {{ wait.startTime | formatDate('HH:ss') }}-->
              <!--      </td>-->
              <!--      <td>-->
              <!--        <div class="none-live">-->
              <!--          <img :src="wait.sport.icon ? wait.sport.icon : wait.league.icon" class="ic"-->
              <!--               style="width: 25px;">-->
              <!--          <div class="n1" style="font-weight: bold;">{{ wait.league | leagueName }}</div>-->
              <!--          <div class="n2" style="font-weight: bold;">-->
              <!--            {{ wait.homeTeam | teamName }}-->
              <!--            vs-->
              <!--            {{ wait.awayTeam | teamName }}-->
              <!--          </div>-->
              <!--        </div>-->
              <!--      </td>-->
              <!--    </tr>-->
              <!--    </tbody>-->
              <!--  </table>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import RenewalInplayDetail from "./RenewalInplayDetail";
//import RenewalInplayDetail2 from "./RenewalInplayDetail2";
import ListPrice from "./ListPrice";
import {mapState} from "vuex";
import { io } from 'socket.io-client';

export default {
  name: "InpList",
  components: {
    RenewalInplayDetail,
    // Category,
    ListPrice,
  },
  props: ['originList', 'addCart', 'checkCart', 'waitList', 'isLoading', 'checkBlockGame', 'blockTypeSetting'],
  data: function(){
    return {
      lsport_socket: null,
      openDetail: [],
    }
  },
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      linkedGame: state => state.inplay.inplayLink,
      user: state => state.user.user,
    }),
  },
  created(){
    this.lsport_socket = io(`https://lsports.j6rgtq98rh95vq5wbqsrdacx3.xyz`);
  },
  mounted: function () {
    console.log(this.lists)
    this.lsport_socket.on('gameUpdate', this.gameUpdate);
    this.lsport_socket.on('oddsUpdate', this.oddsUpdate);
    this.lsport_socket.on('scoreUpdate', this.scoreUpdate);
    // this.$socket.client.on('inplayStats', this.matchStats)
    // this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    // this.$socket.client.on('inplay', this.updateData)
  },
  methods: {
    sortBySports: function (lists) {
      const sports = lists.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    selectedGame(gameId){
      const index = this.openDetail.findIndex((id) => id === gameId);
      console.log(index, 'index');
      console.log(this.openDetail)

      if (index < 0) return this.openDetail.push(gameId);
      return this.openDetail.splice(index, 1);
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.lists.splice(index, 1)
        }
      }

      this.$store.dispatch('DELETE_CART_LSPORT_INPLAY_BY_GAME_SEQ', gameSeq);
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      // console.log(result, '배당변경')
      this.$store.dispatch('UPDATE_LSPORT_INPLAY_LIST', result);
    },
    addStaredGame: function (gameSeq) {
      this.$store.dispatch('TOGGLE_STARED', gameSeq);
    },
    checkArray(gameId) {
      console.log(this.openDetail.indexOf(gameId) >= 0);
      return this.openDetail.indexOf(gameId) >= 0
    },
    gameUpdate(data){
      const result = JSON.parse(data);
      console.log(result, '경기 업데이트');
      for (const game of result) {
        const dbGame = this.lists.findIndex((listGame) => String(listGame.id) === String(game.id));
        if (dbGame >= 0) {
          if (game.status === '종료') this.lists.splice(dbGame, 1)
          // console.log(dbGame, 'DB경기')
        }
      }
    },
    oddsUpdate(data){
      const result = JSON.parse(data);
      this.$store.dispatch('UPDATE_LSPORT_INPLAY_LIST_ODDS', result);

      if (result.length === 0) return;

      for (const folder of result){
        const dbGame = this.lists.find((listGame) => String(listGame.id) === String(folder.gameId));
        // 일치하는 폴더 정보가있을시
        if (dbGame.folders.length === 0) continue;
        const inListfolder = dbGame.folders.find((gameFolder) => gameFolder.id === folder.id);
        if (inListfolder) {
          inListfolder.isSuspended = folder.isSuspended;
          for (const rmqBet of folder.bets) {
            const inBet = inListfolder.bets.find((inLiBet) => inLiBet.id === rmqBet.id);
            // console.log(inBet, '디비 BET');
            inBet.price = rmqBet.price;
            inBet.Status = rmqBet.status;
          }
        }
      }
      // console.log(result, '배당 업데이트');
    },
    scoreUpdate(data){
      const result = JSON.parse(data);
      console.log(result, '스코어 업데이트');
      this.$store.dispatch('UPDATE_LSPORT_INPLAY_LIST_SCORE', result);

      for (const game of result) {
        const dbGame = this.lists.find((listGame) => String(listGame.id) === String(game.id));
        if (dbGame) {
          dbGame.homeResult = game.homeResult;
          dbGame.awayResult = game.awayResult;
          dbGame.currentPeriod = game.currentPeriod;
          dbGame.currentTime = game.currentTime;


          // console.log(dbGame, 'DB 스코어경기');
        }
      }

    },
  },
}
</script>

<style scoped>

</style>
