<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="wrap" style="margin-top: 20px; min-height: 800px;">
      <table
          id="table1"
          class="table2 dataTable no-footer" width="100%" cellpadding="0"
          role="grid">
        <thead>
          <tr role="row">
          <th class="effect_table_t_1 sorting_desc" width="13%" tabindex="0" aria-controls="table1" rowspan="1"
              colspan="1" aria-sort="descending" aria-label="경기시각: activate to sort column ascending">경기시각
          </th>
          <th class="effect_table_t_1 sorting" width="7%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="종목: activate to sort column ascending">종목
          </th>
          <th class="effect_table_t_1 sorting" width="8%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="국가: activate to sort column ascending">국가
          </th>
          <th class="effect_table_t_1 sorting" width="15%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="리그: activate to sort column ascending">리그
          </th>
          <th class="effect_table_t_1 sorting" width="6%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="점수: activate to sort column ascending">점수
          </th>
          <th class="effect_table_t_1 sorting" width="20%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="팀1: activate to sort column ascending">팀1
          </th>
          <th class="effect_table_t_1 sorting" width="5%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="무: activate to sort column ascending">무
          </th>
          <th class="effect_table_t_1 sorting" width="20%" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="팀2: activate to sort column ascending">팀2
          </th>
          <th class="effect_table_t_1 sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
              aria-label="더보기: activate to sort column ascending">더보기
          </th>
        </tr>
        </thead>


        <tbody>
        <template v-for="(row, index) in lists">
          <tr id="20729693" role="row" class="odd" style="background-color: rgb(38, 42, 43);" :key="'gameList' + index">
            <td class="effect_time_1 sorting_1">{{ row.startTime | formatDate('YYYY-MM-DD HH:mm') }}</td>
            <td class="effect_name_1">
              <img width="15" :src="row.sport.icon">
              {{ row.sport | sportName }}
            </td>
            <td class="effect_nation_1">
              <img width="15" :src="row.location.icon">
              {{ row.location | locationName }}
            </td>
            <td class="effect_league_1">{{ row.league | leagueName }}</td>
            <td class="effect_effect_1" style="color:#0d6c9a;">{{ row.homeResult }}:{{ row.awayResult }}</td>
            <td class="effect_home_1">
              <div :class="{'effect_right_2': Number(row.homeResult)> Number(row.awayResult) }" class="effect_right_1">
                {{ row.homeTeam | teamName }}&nbsp;&nbsp;&nbsp;
                <img src="@/assets/img/ico/res_icon001.png" v-if="Number(row.homeResult) > Number(row.awayResult)">
                <img src="@/assets/img/ico/res_icon003-1.png" v-else>
              </div>
            </td>
            <td class="effect_vs_1">
              <div class="effect_center_1">
                <img src="@/assets/img/ico/res_icon001.png" v-if="Number(row.homeResult) === Number(row.awayResult)">
                <img src="@/assets/img/ico/res_icon002-1.png" v-else>
              </div>
            </td>
            <td class="effect_loss_1">
              <div class="effect_left_1" :class="{'effect_left_2': Number(row.homeResult) < Number(row.awayResult) }">
                <img src="@/assets/img/ico/res_icon001.png" v-if="Number(row.homeResult) < Number(row.awayResult)">
                <img src="@/assets/img/ico/res_icon003-1.png" v-else>
                &nbsp;&nbsp;&nbsp;{{ row.awayTeam | teamName }}
              </div>
            </td>
            <td class="effect_m_1" @click="toggleDetail(row.seq)">+ {{ row.foldersCnt.cnt || 0 }}</td>
          </tr>

          <transition name="fade" :key="'gameListDetail' + index">
            <tr v-if="checkArray(row.seq)">
              <td colspan="9">
                <div id="sub_20729691" class="panel-body">
                  <div class="sports_dd_p_2">
                    <table style="width:100%">
                      <tbody>
                        <GameResultDetail :game="row"></GameResultDetail>
<!--                          <GameResultDetail :game="row" :oddeven="false"></GameResultDetail>-->
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </transition>
        </template>
        </tbody>

      </table>
      <Pagination v-if="pagination.end" :payload="payload" :req-data="reqData" :pagination="pagination"></Pagination>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>경기결과</h2>
    </div>

    <!--    <div class="list-days2">-->
    <!--      <ul>-->
    <!--        <li class="active"><button>01/01 [월]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</b utton></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--        <li><button>01-02 [화]</button></li>-->
    <!--      </ul>-->
    <!--    </div>-->

    <div class="sort1">
      <select class="select" v-model="payload.sport">
        <option :value="null">모든 종목</option>
        <option :value="210">축구</option>
        <option :value="212">농구</option>
        <option :value="220">야구</option>
        <option :value="214">배구</option>
        <option :value="215">미식축구</option>
        <option :value="267">E스포츠</option>
      </select>
      <select class="select" v-model="payload.type">
        <option :value="'sports'">스포츠</option>
      </select>
    </div>
    <div v-if="!lists" style="text-align: center; padding-top: 20%; padding-bottom: 20%;">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="m-result" v-else v-for="(row, index) in lists.filter(e => e.folders.length > 0)" :key="'gameResult'+index">
      <div class="head">
        <div class="tit1">
          <img :src="row.sport.icon">
          <span>{{ row.startTime | formatDate('MM/DD HH:mm') }}</span>
          <img :src="row.league.icon">
          <span>{{ row.league | leagueName }}</span>
          <em @click="toggleDetail(index)">+ {{ row.foldersCnt.cnt }} &gt;</em>
        </div>
        <div class="tit2">
          {{ row.homeTeam | teamName }}
          <span class="main_color">{{ row.homeResult }}</span> vs <span class="main_color">{{
            row.awayResult
          }}</span>
          {{ row.awayTeam | teamName }}
        </div>
        <div class="btns">
          <button
              :set="home=row.folders[0].bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"
              :class="{'active': row.folders[0].bets.find(e => e.name === '1' || e.name === 'W1' || e.name === 'Home' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes').settlement === 'win'}"
          >
            승
          </button>
          <button
              v-if="row.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
              :class="{'active': row.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal').settlement === 'win'}"
              :set="draw=row.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
          >무
          </button>
          <button v-else>
            {{
              row.folders[0].market.isHandicap === 'y' && row.folders[0].market.isOverUnder === 'n' ? Number(row.folders[0].line) * -1 : row.folders[0].market.isHandicap === 'y' && row.folders[0].market.isOverUnder === 'y' ? row.folders[0].line : 'VS'
            }}
          </button>
          <button

              :set="away=row.folders[0].bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"
              :class="{'active': row.folders[0].bets.find(e => e.name === '2' || e.name === 'W2' || e.name === 'Away' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No').settlement === 'win'}"
          >
            패
          </button>
        </div>
      </div>
      <div class="body">
        <MGameResultDetail :game="row" v-if="checkArray(index)"></MGameResultDetail>
      </div>
    </div>
    <MPagination v-if="pagination.end" :payload="payload" :req-data="reqData" :pagination="pagination"></MPagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination02'
import MPagination from '@/components/MPagination'
import GameResultDetail from '@/components/Result/GameResultDetail'
import MGameResultDetail from '@/components/Result/MGameResultDetail'

export default {
  name: "GameResult",
  components: {
    Pagination,
    MPagination,
    GameResultDetail,
    MGameResultDetail,
  },
  data: function () {
    return {
      loading: {
        isLoading: true,
        color: '#c3257c',
        size: '150px'
      },
      lists: null,
      payload: {
        page: 1,
        size: 20,
        type: 'sports',
        sport: null,
        date: null,
      },
      pagination: {},
      type: '',
      openDetailResults: [],
    }
  },
  mounted: function () {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function (payload) {
      return this.$store.dispatch('GET_RESULT', { payload })
          .then(data => {
            const result = data.data.payload;
            this.lists = result.list;
            this.pagination = result.pagination;
            this.loading.isLoading = false;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailResults, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailResults.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailResults = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    checkArray: function (id) {
      let result = this.openDetailResults.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
